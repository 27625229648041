/**
 * ReportsPage Component
 *
 * This component renders a page displaying various reports based on the selected control.
 * It uses the PageLayout component for consistent layout across the application.
 *
 * @component
 */
import {
  Container,
  ExpandableSection,
  Header,
  SpaceBetween,
  Tabs,
  TabsProps,
} from '@cloudscape-design/components';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useControls } from 'src/common/ControlContext';
import reportConfigs, { ReportConfig, ReportPageConfig } from '../../common/config/ReportsConfig';
import PageLayout from '../common/PageLayout/PageLayout';

/**
 * Renders a list of reports for a given tab.
 *
 * @param {ReportConfig[]} reports - Array of report configurations for the tab
 * @param {ReportPageConfig} pageConfig - Overall page configuration
 * @returns {JSX.Element} Rendered reports
 */
const renderReports = (reports: ReportConfig[], pageConfig: ReportPageConfig) => {
  return (
    <SpaceBetween size="l">
      {reports.map((report, index) => (
        <Container key={index}>
          <ExpandableSection headerText={report.headerText} defaultExpanded={false}>
            <iframe
              title="iframe"
              width="100%"
              height="1000"
              src={`https://${pageConfig.region}.quicksight.aws.amazon.com/sn/embed/share/accounts/${pageConfig.accountId}/dashboards/${report.dashboardId}?directory_alias=${pageConfig.directoryAlias}`}
            />
          </ExpandableSection>
        </Container>
      ))}
    </SpaceBetween>
  );
};

/**
 * Main ReportsPage component.
 *
 * @returns {JSX.Element} Rendered ReportsPage
 */
export const ReportsPage: React.FC = () => {
  // Get the list of controls using a custom hook
  const { controls } = useControls();

  // Determine the report type based on the controlId
  const { controlId } = useParams<{ controlId: string }>();
  const reportType = controlId?.toUpperCase() as keyof typeof reportConfigs;

  // Find the control matching the controlId
  const control = useMemo(() => {
    return controls.find((c) => c.controlId === controlId) || null;
  }, [controls, controlId]);

  const pageConfig = reportConfigs[reportType];
  const [activeTabId, setActiveTabId] = useState(pageConfig.tabs[0].id);

  // Generate tabs based on the page configuration
  const tabs: TabsProps.Tab[] = pageConfig.tabs.map((tab) => ({
    label: tab.label,
    id: tab.id,
    content: renderReports(tab.reports, pageConfig),
  }));

  return (
    <PageLayout
      header={
        <Header variant="h1" description="">
          {control?.controlSubTitle}
        </Header>
      }
    >
      <SpaceBetween size="l">
        <Tabs
          activeTabId={activeTabId}
          onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
          tabs={tabs}
        />
      </SpaceBetween>
    </PageLayout>
  );
};
