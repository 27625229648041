import {
  ControllershipHubBackendClient,
  ControllershipHubBackendClientConfig,
  GetControlAttributesCommand,
  GetControlAttributesCommandInput,
  GetControlAttributesCommandOutput,
  GetControlAuditLogsCommand,
  GetControlAuditLogsCommandInput,
  GetControlAuditLogsCommandOutput,
  GetControlCodeAuditLogsCommand,
  GetControlCodeAuditLogsCommandInput,
  GetControlCodeAuditLogsCommandOutput,
  GetControlDataCommand,
  GetControlDataCommandInput,
  GetControlDataCommandOutput,
  GetControlsCommand,
  GetControlsCommandInput,
  GetControlsCommandOutput,
  GetHistoricalExecutionDataCommand,
  GetHistoricalExecutionDataCommandInput,
  GetHistoricalExecutionDataCommandOutput,
  GetUserPreferencesCommand,
  GetUserPreferencesCommandInput,
  GetUserPreferencesCommandOutput,
  UpdateControlDataCommand,
  UpdateControlDataCommandInput,
  UpdateControlDataCommandOutput,
  UpdateUserPreferencesCommand,
  UpdateUserPreferencesCommandInput,
  UpdateUserPreferencesCommandOutput,
} from '@amzn/controllershiphub-typescript-client';
import { HttpRequest } from '@aws-sdk/protocol-http';
import { Auth } from 'aws-amplify';
import { DEFAULT_CLIENT_CONFIG, constructApiUrl, getCurrentEnvironment } from './config';

export class ControllershipHub {
  private readonly client: ControllershipHubBackendClient;

  constructor(config: Partial<ControllershipHubBackendClientConfig> = {}) {
    const chubConfig = {
      ...DEFAULT_CLIENT_CONFIG,
      ...config,
      endpoint: config.endpoint ?? constructApiUrl(getCurrentEnvironment()),
    };

    this.client = this.initializeClient(chubConfig);
  }

  private initializeClient(
    config: ControllershipHubBackendClientConfig
  ): ControllershipHubBackendClient {
    const client = new ControllershipHubBackendClient(config);

    client.middlewareStack.add(
      (next) => async (args) => {
        if (!HttpRequest.isInstance(args.request)) {
          return next(args);
        }

        try {
          const session = await Auth.currentSession();
          const token = session.getIdToken().getJwtToken();

          args.request.headers = {
            'Content-Type': 'application/json',
            ...args.request.headers,
            Authorization: token,
          };
        } catch (error) {
          console.error('Authentication failed:', error);
          throw new Error('Failed to authenticate request');
        }

        return next(args);
      },
      {
        step: 'build',
        name: 'authMiddleware',
        priority: 'high',
      }
    );

    return client;
  }

  private validateControlId(input: { controlId?: string }): void {
    if (!input.controlId) {
      throw new Error('Control ID is required');
    }
  }

  async getControlAuditLogs(
    input: GetControlAuditLogsCommandInput
  ): Promise<GetControlAuditLogsCommandOutput> {
    this.validateControlId(input);
    return this.client.send(new GetControlAuditLogsCommand(input));
  }

  async getControlCodeAuditLogs(
    input: GetControlCodeAuditLogsCommandInput
  ): Promise<GetControlCodeAuditLogsCommandOutput> {
    this.validateControlId(input);
    return this.client.send(new GetControlCodeAuditLogsCommand(input));
  }
  async getControlAttributes(
    input: GetControlAttributesCommandInput
  ): Promise<GetControlAttributesCommandOutput> {
    this.validateControlId(input);
    return this.client.send(new GetControlAttributesCommand(input));
  }

  async getControlData(input: GetControlDataCommandInput): Promise<GetControlDataCommandOutput> {
    this.validateControlId(input);
    return this.client.send(new GetControlDataCommand(input));
  }

  async getControls(input: GetControlsCommandInput = {}): Promise<GetControlsCommandOutput> {
    return this.client.send(new GetControlsCommand(input));
  }

  async getHistoricalExecutionData(
    input: GetHistoricalExecutionDataCommandInput
  ): Promise<GetHistoricalExecutionDataCommandOutput> {
    this.validateControlId(input);
    return this.client.send(new GetHistoricalExecutionDataCommand(input));
  }

  async updateControlData(
    input: UpdateControlDataCommandInput
  ): Promise<UpdateControlDataCommandOutput> {
    this.validateControlId(input);
    return this.client.send(new UpdateControlDataCommand(input));
  }

  async getUserPreferences(
    input: GetUserPreferencesCommandInput
  ): Promise<GetUserPreferencesCommandOutput> {
    this.validateControlId(input);
    return this.client.send(new GetUserPreferencesCommand(input));
  }

  async updateUserPreferences(
    input: UpdateUserPreferencesCommandInput
  ): Promise<UpdateUserPreferencesCommandOutput> {
    return this.client.send(new UpdateUserPreferencesCommand(input));
  }
}

export const controllershipHub = new ControllershipHub();
export default controllershipHub;
