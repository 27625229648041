import PropertyFilter from '@cloudscape-design/components/property-filter';

interface AuditTrailPropertyFilterProps {
  propertyFilterProps: any;
  filteringOptions: { propertyKey: string; value: string }[];
  filteredItemsCount: number | undefined;
}

const AuditTrailPropertyFilter = ({
  propertyFilterProps,
  filteringOptions,
  filteredItemsCount,
}: AuditTrailPropertyFilterProps) => {
  return (
    <PropertyFilter
      {...propertyFilterProps}
      filteringOptions={filteringOptions}
      countText={`${filteredItemsCount} matches`}
      filteringPlaceholder="Filter audit logs"
      filteringAriaLabel="Filter audit logs"
      i18nStrings={{
        applyActionText: 'Apply',
        clearFiltersText: 'Clear filters',
        filteringAriaLabel: 'Filter audit logs',
        dismissAriaLabel: 'Dismiss',
        groupValuesText: 'Values',
        groupPropertiesText: 'Properties',
        operatorsText: 'Operators',
        operationAndText: 'and',
        operationOrText: 'or',
        operatorContainsText: 'Contains',
        operatorDoesNotContainText: 'Does not contain',
        operatorEqualsText: 'Equals',
        operatorDoesNotEqualText: 'Does not equal',
        removeTokenButtonAriaLabel: (token) =>
          `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
        enteredTextLabel: (text) => `Use: "${text}"`,
      }}
    />
  );
};

export default AuditTrailPropertyFilter;
