import React, { useRef } from 'react';
import { Popover } from '@cloudscape-design/components';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { ICellRendererParams } from 'ag-grid-community';

/**
 * Renders cell content with popover functionality for AG-Grid. Handles both plain text and structured content (alias, timestamp, message).
 * Automatically truncates content > 45 chars with "show more" option. For structured content, displays as "[alias] | [date] ⟫ [message]".
 * Structured format expects: { alias?: string, timestamp?: string, content?: string }. Alias links to Phonetool.
 * @param {ICellRendererParams} props - AG-Grid cell renderer parameters containing the value to display
 */
export const CustomCellPopoverRenderer = (props: ICellRendererParams) => {
  const popoverRef = useRef<HTMLElement>(null);

  if (!props.value) {
    return <div></div>;
  }

  let content: string;
  let structuredContent: { alias?: string; timestamp?: string; content?: string } | null = null;

  // Try to parse as JSON, if it fails, treat as plain text
  try {
    const parsedValue = typeof props.value === 'string' ? JSON.parse(props.value) : props.value;
    if (typeof parsedValue === 'object' && parsedValue !== null) {
      structuredContent = parsedValue;
      content = parsedValue.content || '';
    } else {
      content = String(props.value);
    }
  } catch (e) {
    content = String(props.value);
  }

  const ShowMorePopover = ({ fullContent }: { fullContent: React.ReactNode }) => (
    <Popover
      dismissButton={true}
      position="right"
      size="large"
      triggerType="custom"
      content={
        <div
          style={{
            maxWidth: '400px',
            maxHeight: '400px',
            overflow: 'auto',
            padding: '1px',
          }}
        >
          {fullContent}
        </div>
      }
      renderWithPortal
      ref={popoverRef}
    >
      <span
        style={{
          color: '#0972d3',
          cursor: 'pointer',
          textDecoration: 'none',
          marginLeft: '4px',
          fontSize: '13px',
        }}
      >
        show more
      </span>
    </Popover>
  );

  const ContentComponent = ({ isFullContent = false }) => {
    if (structuredContent && structuredContent.alias && structuredContent.timestamp) {
      let formattedDateTime;
      try {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const date = parseISO(structuredContent.timestamp);
        formattedDateTime = formatInTimeZone(date, timeZone, 'MMM d, yyyy, h:mm a zzz');
      } catch (error) {
        console.error('Error parsing timestamp:', error);
        formattedDateTime = structuredContent.timestamp;
      }

      const fullText = `${structuredContent.alias} | ${formattedDateTime} ⟫ ${content}`;
      const truncatedText = fullText.length > 68 ? `${fullText.slice(0, 68)}...` : fullText;

      const fullContent = (
        <>
          <a
            href={`https://phonetool.amazon.com/users/${structuredContent.alias}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#1166c0',
              textDecoration: 'none',
              marginLeft: '3px',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {structuredContent.alias}
          </a>
          <span style={{ color: '#666', margin: '0 3px' }}>|</span>
          <span>{formattedDateTime}</span>
          <span style={{ color: '#666', margin: '0 8px' }}>⟫</span>
          <span>{content}</span>
        </>
      );

      // Create truncated text with clickable alias
      const truncatedWithLink = (
        <span>
          <a
            href={`https://phonetool.amazon.com/users/${structuredContent.alias}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#1166c0',
              textDecoration: 'none',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {structuredContent.alias}
          </a>
          {truncatedText.substring(structuredContent.alias.length)}
        </span>
      );

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isFullContent ? (
            fullContent
          ) : (
            <>
              {truncatedWithLink}
              {fullText.length > 68 && <ShowMorePopover fullContent={fullContent} />}
            </>
          )}
        </div>
      );
    } else {
      const truncatedText = content.length > 68 ? `${content.slice(0, 68)}...` : content;
      return (
        <div>
          {isFullContent ? (
            content
          ) : (
            <>
              <span>{truncatedText}</span>
              {content.length > 68 && <ShowMorePopover fullContent={content} />}
            </>
          )}
        </div>
      );
    }
  };

  return (
    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <ContentComponent isFullContent={false} />
    </div>
  );
};
