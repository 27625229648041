import { AuditLog } from '@amzn/controllershiphub-typescript-client';
import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Badge,
  Box,
  Button,
  Header,
  SpaceBetween,
  Table,
  TableProps,
} from '@cloudscape-design/components';
import React from 'react';
import EmptyState from '../common/EmptyState/EmptyState';
import { columnDefinitions } from './AuditTrailColumnDefinition';
import AuditTrailPropertyFilter from './AuditTrailPropertyFilter';
import { AuditLogItem } from './interface';
import { flattenAuditLogs } from './utils';

export interface AuditLogsTableProps {
  auditLogs: AuditLog[];
  controlType: string;
  isPending: boolean;
  isFetching: boolean;
  onRefresh: () => void;
}

const AuditTrailTable = ({
  auditLogs,
  controlType,
  isPending,
  isFetching,
  onRefresh,
}: AuditLogsTableProps) => {
  const flattenedAuditLogs: AuditLogItem[] = React.useMemo(
    () => flattenAuditLogs(auditLogs),
    [auditLogs]
  );

  const { items, collectionProps, propertyFilterProps, filteredItemsCount } = useCollection(
    flattenedAuditLogs,
    {
      propertyFiltering: {
        filteringProperties: [
          {
            key: 'controlCode',
            operators: ['='],
            propertyLabel: `${controlType}`,
            groupValuesLabel: `${controlType} values`,
          },
          {
            key: 'modifiedBy',
            operators: ['='],
            propertyLabel: 'Modified By',
            groupValuesLabel: 'Modified By values',
          },
          {
            key: 'field',
            operators: ['='],
            propertyLabel: 'Modified Field',
            groupValuesLabel: 'Modified Field values',
          },
        ],
      },
    }
  );

  const filteringOptions = React.useMemo(() => {
    const uniqueOptions = new Set<string>();
    const options: { propertyKey: string; value: string }[] = [];

    flattenedAuditLogs.forEach((auditLogItem) => {
      const controlCodeKey = `controlCode-${auditLogItem?.controlCode}`;
      const modifiedByKey = `modifiedBy-${auditLogItem?.modifiedBy}`;
      const fieldKey = `field-${auditLogItem?.field}`;

      if (!uniqueOptions.has(controlCodeKey)) {
        uniqueOptions.add(controlCodeKey);
        options.push({ propertyKey: 'controlCode', value: auditLogItem?.controlCode as string });
      }

      if (!uniqueOptions.has(modifiedByKey)) {
        uniqueOptions.add(modifiedByKey);
        options.push({ propertyKey: 'modifiedBy', value: auditLogItem?.modifiedBy as string });
      }

      if (!uniqueOptions.has(fieldKey)) {
        uniqueOptions.add(fieldKey);
        options.push({ propertyKey: 'field', value: auditLogItem?.field as string });
      }
    });

    return options;
  }, [flattenedAuditLogs]);

  const tableColumns = React.useMemo(
    () => columnDefinitions(controlType),
    [controlType]
  ) as TableProps.ColumnDefinition<any>[];

  const tableHeader = React.useMemo(
    () => (
      <Header
        actions={
          <Button
            iconName="refresh"
            variant="normal"
            onClick={onRefresh}
            loading={isFetching}
            ariaLabel="Refresh audit logs"
          >
            Refresh
          </Button>
        }
      >
        <SpaceBetween size="xs" direction="horizontal" alignItems="center">
          <Box variant="h1">Audit Trail</Box>
          <Box variant="h3" color="text-body-secondary">
            ({filteredItemsCount})
          </Box>
          <Badge color="blue">{auditLogs[0]?.controlId || ''}</Badge>
        </SpaceBetween>
      </Header>
    ),
    [filteredItemsCount, auditLogs, isFetching, onRefresh]
  );

  return (
    <Table
      {...collectionProps}
      stickyHeader
      stripedRows
      items={items}
      loading={isPending}
      contentDensity="compact"
      sortingDisabled={false}
      columnDefinitions={tableColumns}
      variant="container"
      loadingText="Loading audit logs"
      header={tableHeader}
      empty={<EmptyState title="No audit logs found" description="Try adjusting your filters" />}
      filter={
        <AuditTrailPropertyFilter
          propertyFilterProps={propertyFilterProps}
          filteringOptions={filteringOptions}
          filteredItemsCount={filteredItemsCount}
        />
      }
    />
  );
};

export default AuditTrailTable;
