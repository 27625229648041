// FilterSet.tsx
import React from 'react';
import { ButtonDropdown } from '@cloudscape-design/components';
import { UserViewPreferences } from '@amzn/controllershiphub-typescript-client';

export const FilterSet: React.FC<{
  filterViews: Record<string, UserViewPreferences>; // Updated type
  onFilterSetSelect: (viewName: string) => void;
  loading?: boolean;
  selectedView?: string;
}> = ({ filterViews, onFilterSetSelect, loading = false, selectedView = 'Select View' }) => {
  const filterSetItems = Object.keys(filterViews).map((viewName) => ({
    id: viewName,
    text: viewName,
    disabled: false,
    selected: viewName === selectedView,
  }));

  return (
    <ButtonDropdown
      data-testid="view-selection-dropdown"
      items={filterSetItems}
      onItemClick={({ detail }) => onFilterSetSelect(detail.id)}
      loading={loading}
    >
      {selectedView}
    </ButtonDropdown>
  );
};
