import { ColDef, GridOptions, GetRowIdParams } from 'ag-grid-community';
import { AttributeMetadata } from '@amzn/controllershiphub-typescript-client';
import { CustomCellEditor } from '../common/CustomCellEditor';
import './styles.scss';

/**
 * Creates column definitions for an AG Grid based on attribute metadata
 * @param controlAttributesData - Contains metadata for grid columns/attributes
 * @param editedCells - Set containing IDs of cells that have been modified
 * @param CustomCellPopoverRenderer - Custom renderer component for modal view cells
 * @returns Array of column definitions for AG Grid
 */
export const createColumnDefs = (
  controlAttributesData: any,
  editedCells: Set<string>,
  CustomCellPopoverRenderer: any
): ColDef[] => {
  if (!controlAttributesData) return [];

  //TODO - Move to backend configs
  // Adding the renamed attributes for backward compatible.
  const multilineColumns = [
    'Classification Reason',
    'Cost Center Comments',
    'Finance Comments PQ',
    'Accounting Comments PQ',
    'Finance Comments',
    'Accounting Comments',
    'Classification Reason LC',
    'Clause Type',
    'cr',
    'ccCmnts',
    'pqFinCmnts',
    'pqAcctCmnts',
    'finCmnts',
    'acctCmnts',
    'crLc',
    'clause',
  ];

  // Adding the renamed attributes for backward compatible. Will be removed as part of cleanup task.
  const PARAGRAPH_SPLIT_COLUMNS = [
    'Classification Reason',
    'Classification Reason LC',
    'cr',
    'crLc',
  ];

  // Create a utility function for the paragraph splitting logic
  const handleParagraphSplitting = (value: string): string => {
    if (!value) return '';
    const paragraphs = value
      .split('</p>')
      .map((p: string) => p.replace(/<p>/g, '').trim())
      .filter((p: string) => p.length > 0);
    return paragraphs.join('\n');
  };

  return (
    controlAttributesData.attributesMetadata
      ?.map((attr: AttributeMetadata): ColDef | null => {
        const fieldName = attr.name;
        if (!fieldName) {
          console.warn('Attribute name is undefined', attr);
          return null;
        }

        const baseColumnConfig = {
          field: fieldName,
          headerName: attr.isEditable
            ? `${attr.displayName || fieldName} ✎`
            : attr.displayName || fieldName,
          editable: attr.isEditable || false,
          pinned: attr.isOverridable,
          minWidth: 180,
          flex: 1,
          wrapHeaderText: true,
          resizable: true,
          headerTooltip: attr.displayName,
          hide: !attr.isVisibleByDefault,
          headerClass: attr.isEditable ? 'editable-header' : '', // Add this line
          cellClass: (params: any) => {
            const cellId = `${params.node.id}-${params.column.colId}`;
            return editedCells.has(cellId) ? '' : attr.isEditable ? 'editable-column' : '';
          },
        };

        // Enhanced configuration for multiline columns
        if (multilineColumns.includes(attr.name || '')) {
          return {
            ...baseColumnConfig,
            minWidth: 475,
            wrapText: true,
            cellEditor: CustomCellEditor,
            cellRenderer: PARAGRAPH_SPLIT_COLUMNS.includes(fieldName)
              ? (params: any) => handleParagraphSplitting(params.value)
              : CustomCellPopoverRenderer,
            cellStyle: (params: any) => ({
              whiteSpace: 'pre-line',
              padding: '0.5px',
              lineHeight: '1.2',
              ...(editedCells.has(`${params.node.id}-${params.column.colId}`)
                ? { backgroundColor: '#8fc7f0' }
                : {}),
            }),
          };
        }

        // Updated checkbox handling
        if (attr.dataType === 'java.lang.Boolean') {
          return {
            ...baseColumnConfig,
            cellDataType: 'boolean',
            cellEditor: 'agCheckboxCellEditor',
            cellRenderer: 'agCheckboxCellRenderer',
            // Convert string values to boolean for the checkbox
            valueGetter: (params: any) => {
              const value = params.data[params.column.colId];
              return value === true || value === 'true';
            },
            valueSetter: (params: any) => {
              params.data[params.column.colId] = params.newValue;
              return true;
            },
            cellStyle: (params: any) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: editedCells.has(`${params.node.id}-${params.column.colId}`)
                ? '#8fc7f0'
                : 'inherit',
            }),
          };
        }

        // Standard configuration for other columns
        return {
          ...baseColumnConfig,
          cellDataType: 'text',
          cellEditor: attr.dataType === 'java.util.List' ? 'agSelectCellEditor' : CustomCellEditor,
          cellRenderer: attr.enableModalView ? CustomCellPopoverRenderer : undefined,
          cellEditorParams:
            attr.dataType === 'java.util.List' ? { values: ['-', 'Yes', 'No'] } : undefined,
          cellStyle: (params: any) => ({
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            backgroundColor: editedCells.has(`${params.node.id}-${params.column.colId}`)
              ? '#8fc7f0'
              : 'inherit',
          }),
        };
      })
      .filter((colDef: ColDef | null): colDef is ColDef => colDef !== null) ?? []
  );
};

/**
 * Creates grid options configuration for AG Grid
 * @param defaultColDef - Default column properties
 * @param rowData - Array of data to be displayed in the grid
 * @param columnDefs - Column definitions for the grid
 * @param onCellValueChanged - Callback function for cell value changes
 * @param onGridReady - Callback function when grid is initialized
 * @returns GridOptions configuration object
 */
export const createGridOptions = (
  defaultColDef: any,
  rowData: any[],
  columnDefs: ColDef[],
  onCellValueChanged: any,
  onGridReady: any
): GridOptions => ({
  defaultColDef: {
    ...defaultColDef,
    resizable: true,
    wrapText: true,
  },
  components: {
    customCellEditor: CustomCellEditor,
  },
  rowData,
  columnDefs,
  onCellValueChanged,
  suppressColumnVirtualisation: true,
  pagination: true,
  paginationPageSize: 300,
  paginationPageSizeSelector: [100, 200, 300, 500],
  onGridReady,
  domLayout: 'normal',
  suppressRowTransform: true,
  rowHeight: 50,
  cellSelection: true,
  getRowId: (params: GetRowIdParams) => {
    return params.data.id;
  },
});
