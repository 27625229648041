import {
  ControlAttributesMetadata,
  GetControlDataOutput,
  RecordAttributes,
} from '@amzn/controllershiphub-typescript-client';
import { Flashbar, FlashbarProps, Link, Spinner, Tabs } from '@cloudscape-design/components';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, ApiPaths } from 'src/common/config/ApiConstants';
import { useControls } from 'src/common/ControlContext';
import ControlMetadataContent from 'src/common/ControlMetadataContent';
import { logger } from 'src/logger';
import { apiCall } from 'src/utils/ApiCall';
import { GetControlAttributesMetadataResponse } from 'src/utils/ApiInterfaces';
import PageLayout from '../common/PageLayout/PageLayout';
import { ControlSummaryPageAGGrid } from './ControlSummaryPageAGGrid';
import { useControlAuditLogs } from 'src/api/hooks';
import AuditTrailTable from '../AuditTrail/AuditTrailTable';
import { Breadcrumbs } from 'src/common/Breadcrumbs';

/**
 * Check if a record is empty
 * @param {RecordAttributes[]} record - The record to check
 * @returns {boolean} True if the record is empty, false otherwise
 */
export const isRecordEmpty = (record: RecordAttributes[]): boolean => {
  // If the record is null or empty, consider it empty
  if (record == null || record.length === 0) return true;
  // Check if all attributes in the record are empty (empty string or null)
  return record.every((attr) =>
    Object.values(attr).every((value) => value === '' || value === null)
  );
};

/**
 * Filter out empty records from the data
 * @param {RecordAttributes[][]} data - The data to filter
 * @returns {RecordAttributes[][]} Filtered data with non-empty records
 */
export const filterAndCleanRecords = (data: RecordAttributes[][]): RecordAttributes[][] => {
  return data.filter((record) => !isRecordEmpty(record));
};

export const SummaryPage = () => {
  const { controls } = useControls();
  const [activeTabId, setActiveTabId] = useState('table');
  const [controlAttributesData, setControlAttributesData] =
    React.useState<ControlAttributesMetadata | null>(null);
  const [controlData, setControlData] = useState<RecordAttributes[][]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [items, setItems] = useState<Record<string, any>[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { controlId } = useParams<{ controlId: string }>();
  const safeControlId = controlId ?? '';
  const [errorMessages, setErrorMessages] = useState<readonly FlashbarProps.MessageDefinition[]>(
    []
  );

  const {
    data: auditData,
    refetch,
    isPending: isPendingAudits,
    isFetching,
  } = useControlAuditLogs({ controlId: controlId });

  // Memoize the control object based on the controlId
  const control = useMemo(() => {
    // Find the control in the controls array that matches the controlId, or return null if not found
    return controls.find((c) => c.controlId === controlId) || null;
  }, [controls, controlId]);

  // Effect to fetch control attributes
  useEffect(() => {
    // Reset states when controlId changes
    setControlData([]);
    setControlAttributesData(null);
    setIsLoading(true);
    setError(null);
    setItems([]);
    setErrorMessages([]);

    const fetchControlAttributes = async () => {
      try {
        // Make an API call to get control attributes metadata
        const data = await apiCall<GetControlAttributesMetadataResponse>({
          endpoint: ApiPaths.getControlAttributes + `/${controlId}`,
        });
        // Update the state with the fetched data
        setControlAttributesData(data.controlAttributesMetadata);
      } catch (err) {
        setError('Unknown API error has occurred');
      }
    };
    fetchControlAttributes();
  }, [controlId]); // Run when controlId changes

  // Effect hook to fetch and process control data
  useEffect(() => {
    let isMounted = true; // Flag to track if component is mounted

    // Asynchronous function to fetch data
    const fetchData = async () => {
      let hasNextPage: string | undefined = ' ';
      const limit = 750; // Number of records to fetch per API call
      try {
        // Loop to fetch paginated data
        do {
          // Make an API call to get control data
          const data: GetControlDataOutput = await apiCall<GetControlDataOutput>({
            endpoint: ApiPaths.getControlData + `/${controlId}/`,
            queryParams: { limit: limit, exclusiveStartKey: hasNextPage },
          });

          // Filter out completely empty records
          const filteredRecords = filterAndCleanRecords(data.controlData?.records ?? []);
          hasNextPage = data.lastEvaluatedKey; // Get the key for the next page
          // Update state only if component is still mounted
          if (isMounted) {
            // Update the state with the new filtered records
            setControlData((prevData) => [...prevData, ...filteredRecords]);
          }
        } while (hasNextPage !== undefined && hasNextPage);
      } catch (error) {
        // Log error if component is still mounted
        if (isMounted) {
          if (error instanceof ApiError && error.status == 401) {
            setIsLoading(false);
            setError('Unauthorized');
          }
          logger.error('Error fetching data: ' + error);
        }
      } finally {
        // Set loading state to false when done, if component is still mounted
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    fetchData(); // Call the fetch function

    // Cleanup function to prevent state updates if component unmounts
    return () => {
      isMounted = false;
    };
  }, [controlId]); // Run when controlId changes

  /**
   * Render the table content based on the current state
   * @returns {JSX.Element} The rendered table or loading message
   */
  const renderTableContent = () => {
    // If control attributes data is available and control data is not empty
    if (controlAttributesData && controlData.length > 0) {
      // Render the ControlSummaryTable component
      return (
        <ControlSummaryPageAGGrid
          controlData={controlData}
          controlAttributesData={controlAttributesData}
          isLoading={isLoading}
          controlId={safeControlId}
        />
      );
    } else {
      // If data is not ready, render a loading message using Flashbar
      return (
        !error && (
          <Flashbar
            items={[
              {
                type: 'warning',
                loading: true,
                content: 'Data is loading. Please wait for filtering and control execution',
                dismissible: false,
                dismissLabel: 'Dismiss message',
                onDismiss: () => setItems([]),
                id: 'message_1',
              },
            ]}
          />
        )
      );
    }
  };

  useEffect(() => {
    if (error) {
      setErrorMessages([
        {
          header: 'Error',
          type: 'error' as const,
          content: error.toString(),
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setErrorMessages([]),
          id: 'error_message',
        },
      ]);
    } else {
      setErrorMessages([]);
    }
  }, [error]);

  return controls.length > 0 ? (
    <>
      <PageLayout defaultPadding={true} notifications={<Flashbar items={errorMessages} />}>
        {/* Breadcrumbs - Only shown when not on the home page */}
        {location.pathname !== '/' ? (
          <Breadcrumbs
            items={[
              { text: 'Controllership Hub', href: '/' },
              {
                text: `${control?.controlName}`,
                href: `/${control?.controlType?.toLowerCase()}/${encodeURIComponent(safeControlId)}`,
              },
              { text: `${control?.controlName} - ${control?.controlSubTitle}`, href: '' },
            ]}
          />
        ) : undefined}
        <Tabs
          activeTabId={activeTabId}
          onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
          tabs={[
            {
              label: 'Control Data',
              id: 'table',
              content: renderTableContent(),
            },
            {
              label: 'Information',
              id: 'information',
              content: (
                <ControlMetadataContent
                  controlAttributesData={controlAttributesData}
                  control={control}
                />
              ),
            },
            {
              label: 'Audit Trail',
              id: 'auditTrail',
              content: (
                <>
                  {auditData?.auditLogs && (
                    <AuditTrailTable
                      auditLogs={auditData.auditLogs}
                      isPending={isPendingAudits}
                      isFetching={isFetching}
                      controlType="Airport Code"
                      onRefresh={() => refetch()}
                    />
                  )}
                </>
              ),
            },
          ]}
        />
      </PageLayout>
    </>
  ) : (
    <Spinner />
  );
};
