export const queryKeys = {
  controls: {
    all: ['controls'] as const,
    list: () => [...queryKeys.controls.all, 'list'] as const,
    attributes: (id: string = '') => [...queryKeys.controls.all, id, 'attributes'] as const,
    data: (id: string = '') => [...queryKeys.controls.all, id, 'data'] as const,
    historical: (id: string = '') =>
      [...queryKeys.controls.all, id, 'historical-execution'] as const,
  },
  audits: {
    all: ['audits'] as const,
    list: () => [...queryKeys.audits.all, 'list'] as const,
    control: (id: string = '') => [...queryKeys.audits.all, id] as const,
    code: (id: string = '') => [...queryKeys.audits.all, id, 'code'] as const,
  },
  preferences: {
    all: ['preferences'] as const,
    user: (id: string = '') => [...queryKeys.preferences.all, id] as const,
  },
} as const;
