import { AppLayout } from '@cloudscape-design/components';

import { useUserAlias } from 'src/common/hooks/useUserAlias';
import { PrivateRoute } from 'src/common/PrivateRoute';
import TopNav from '../../navigation/TopNav';
import styles from './styles.module.scss';
import ToolsNav from 'src/components/navigation/ToolsNav';

const HEADER_ID = 'h';

const AppContainer = () => {
  const { alias: userAlias } = useUserAlias();

  const SIM_ISSUE_LINK =
    'https://sim.amazon.com/issues/create?assignedFolder=9734f5a7-1b6a-4016-909d-ffbc99a50513';
  const WIKI_ABOUT_LINK =
    'https://w.amazon.com/bin/view/WWCF_IPAT/FinTech/Controllership/Controllership_Hub/';

  return (
    <div className={styles.root}>
      <div id={HEADER_ID} className={styles.topNavigation}>
        <TopNav userAlias={userAlias} issueLink={SIM_ISSUE_LINK} aboutLink={WIKI_ABOUT_LINK} />
      </div>
      <div className={styles.appLayout}>
        <AppLayout
          toolsHide
          navigationHide
          toolsWidth={500}
          tools={<ToolsNav />}
          navigationOpen={false}
          disableContentPaddings={true}
          headerSelector={`#${HEADER_ID}`}
          maxContentWidth={Number.MAX_VALUE}
          content={<PrivateRoute />}
        />
      </div>
    </div>
  );
};

export default AppContainer;
