import { format } from 'date-fns';

/**
 * Formats an ISO timestamp string into a human-readable date and time format
 * @param timestamp - ISO 8601 timestamp string (e.g., "2023-12-25T15:30:00Z")
 * @returns Formatted date string (e.g., "Dec 25, 2023 15:30:00")
 *
 * @example
 * formatTimestamp("2023-12-25T15:30:00Z")
 * Returns => "Dec 25, 2023 15:30:00"
 */
export const formatTimestamp = (timestamp: string): string => {
  return format(new Date(timestamp), 'MMM dd, yyyy HH:mm:ss');
};
