import React, { useRef } from 'react';
import { Popover } from '@cloudscape-design/components';
import { format, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
  ICellRendererParams,
  FilterModel,
  GridApi,
  CellValueChangedEvent,
} from 'ag-grid-community';
import {
  RecordAttributes,
  ControlAttributesMetadata,
  UpdateUserPreferencesInput,
  GetUserPreferencesOutput,
  UserViewPreferences,
} from '@amzn/controllershiphub-typescript-client';
import { apiCall, ApiCallProps } from 'src/utils/ApiCall';
import { ApiPaths } from 'src/common/config/ApiConstants';

// TODO - Move to backend configs
export enum CommentFieldType {
  TIMESTAMP_ONLY = 'TIMESTAMP_ONLY', // For same source and target columns
  STANDARD = 'STANDARD', // For different source and target columns
}

interface FieldConfig {
  sourceColumn: string;
  targetColumn: string;
  type: CommentFieldType;
}

interface AutoPopulateParams {
  event: CellValueChangedEvent;
  configs: FieldConfig[];
  alias: string;
}

// Main auto-populate handler
interface PendingUpdate {
  targetColumn: string;
  sourceColumn: string;
  sourceValue: string;
  timestamp: string;
  alias: string;
  type: CommentFieldType;
  rowId: string;
}

// Column configurations for AutoPopulate. TODO - Move to backend configs
const FIELD_CONFIGS: FieldConfig[] = [
  {
    sourceColumn: 'cc',
    targetColumn: 'ccCmnts',
    type: CommentFieldType.STANDARD,
  },
  {
    sourceColumn: 'ccCmnts',
    targetColumn: 'ccCmnts',
    type: CommentFieldType.TIMESTAMP_ONLY,
  },
  {
    sourceColumn: 'aro',
    targetColumn: 'finCmnts',
    type: CommentFieldType.STANDARD,
  },
  {
    sourceColumn: 'finCmnts',
    targetColumn: 'finCmnts',
    type: CommentFieldType.TIMESTAMP_ONLY,
  },
  {
    sourceColumn: 'ack',
    targetColumn: 'acctCmnts',
    type: CommentFieldType.STANDARD,
  },
  {
    sourceColumn: 'acctCmnts',
    targetColumn: 'acctCmnts',
    type: CommentFieldType.TIMESTAMP_ONLY,
  },
  // Add other configurations following the same pattern
];

// Function to format timestamp
export const formatTimestamp = () => {
  return new Date().toISOString(); // This returns the date in UTC
};

// Add this to track pending updates
export const pendingUpdates: PendingUpdate[] = [];

export const handleAutoPopulate = ({ event, configs, alias }: AutoPopulateParams): void => {
  const config = configs.find((cfg) => cfg.sourceColumn === event.column.getColId());
  if (!config) return;

  const timestamp = formatTimestamp();

  // Generate a rowId using multiple fallback options
  const rowId =
    event.node?.id || event.node?.rowIndex?.toString() || `row-${btoa(JSON.stringify(event.data))}`;

  pendingUpdates.push({
    targetColumn: config.targetColumn,
    sourceColumn: config.sourceColumn,
    sourceValue: event.newValue,
    timestamp,
    alias,
    type: config.type,
    rowId: rowId,
  });
};

// Create configs function
export const createAutoPopulateConfigs = (): FieldConfig[] => FIELD_CONFIGS;

/**
 * Handles saving changes made to grid data
 * @param rowData - Array of row data from the grid
 * @param editedCells - Set of cell IDs that have been modified
 * @param controlAttributesData - Metadata about control attributes
 * @param controlId - Identifier for the control being updated
 * @throws Error if control dataset key is missing or no changes to save
 * @returns Promise that resolves when save is complete
 */
export const handleSaveChanges = async (
  rowData: Record<string, any>[],
  editedCells: Set<string>,
  controlAttributesData: ControlAttributesMetadata | null,
  controlId: string,
  originalValues: Record<string, any> // Add originalValues parameter
): Promise<Record<string, any>[]> => {
  const controlDatasetKey = controlAttributesData?.controlDatasetKey;

  if (!controlDatasetKey) {
    throw new Error('Control dataset key is not available');
  }

  // Create a deep copy of rowData
  const updatedRowData = JSON.parse(JSON.stringify(rowData));

  // Create a map to track which cells have been updated
  const updatedCells = new Set<string>();

  // Sort the updates so TIMESTAMP_ONLY fields are processed first
  const sortedUpdates = [...pendingUpdates].sort((a, b) => {
    if (a.type === CommentFieldType.TIMESTAMP_ONLY && b.type !== CommentFieldType.TIMESTAMP_ONLY) {
      return -1;
    }
    if (b.type === CommentFieldType.TIMESTAMP_ONLY && a.type !== CommentFieldType.TIMESTAMP_ONLY) {
      return 1;
    }
    return 0;
  });
  // Apply pending updates to specific cells only
  sortedUpdates.forEach((update) => {
    const rowIndex = updatedRowData.findIndex(
      (row: Record<string, any>) => row.id === update.rowId
    );
    if (rowIndex === -1) return;

    const row = updatedRowData[rowIndex];
    const sourceCellId = `${update.rowId}-${update.sourceColumn}`;
    const targetCellId = `${update.rowId}-${update.targetColumn}`;

    // Only apply update if this specific cell was edited and hasn't been updated yet
    if (editedCells.has(sourceCellId) && !updatedCells.has(targetCellId)) {
      // Update source value
      row[update.sourceColumn] = update.sourceValue;

      // Create the comment for the target column
      const formatValue = (value: any) => {
        if (value === false) return 'false';
        return value || '';
      };

      const newValue = {
        alias: update.alias || '',
        timestamp: formatTimestamp(),
        content:
          update.type === CommentFieldType.TIMESTAMP_ONLY
            ? formatValue(update.sourceValue)
            : `Changed ${update.sourceColumn} to: ${formatValue(update.sourceValue)}`,
      };

      row[update.targetColumn] = JSON.stringify(newValue);

      updatedCells.add(targetCellId);
    }
  });

  // Clear pending updates
  pendingUpdates.length = 0;

  const controlKeyToUpdatesMap: { [key: string]: RecordAttributes[] } = {};

  updatedRowData.forEach((row: Record<string, any>) => {
    const controlDatasetKeyValue = row[controlDatasetKey];
    const updatedAttributes: RecordAttributes[] = [];

    Object.entries(row).forEach(([key, value]) => {
      const cellId = `${row.id}-${key}`;
      // Check if cell is edited/updated AND value is different from original
      if (
        (editedCells.has(cellId) || updatedCells.has(cellId)) &&
        originalValues[cellId] !== value
      ) {
        updatedAttributes.push({
          name: key,
          value: typeof value === 'boolean' ? value.toString() : value,
        });
      }
    });

    if (updatedAttributes.length > 0) {
      controlKeyToUpdatesMap[controlDatasetKeyValue as string] = updatedAttributes;
    }
  });

  if (Object.keys(controlKeyToUpdatesMap).length === 0) {
    throw new Error('No changes to save');
  }

  const response = await apiCall<{ results: { name: string; status: string }[] }>({
    endpoint: `${ApiPaths.updateControlData}/${controlId}`,
    method: 'PATCH',
    body: { controlKeyToUpdatesMap },
  });

  if (!response.results?.every((result) => result.status === 'SUCCESS')) {
    const failedResults = response.results.filter((result) => result.status !== 'SUCCESS');
    throw new Error(`Save failed for: ${failedResults.map((r) => r.name).join(', ')}`);
  }
  return updatedRowData;
};

/**
 * Processes an array of control data records and transforms them into a simplified object structure.
 *
 * @param controlData - A two-dimensional array of RecordAttributes objects containing control data
 * @returns An array of objects where each object contains name-value pairs from the record set
 *
 * @example
 * const data = [[{ name: 'field1', value: 'value1' }, { name: 'field2', value: 'value2' }]]
 * const result = processControlData(data)
 * // Result: [{ field1: 'value1', field2: 'value2' }]
 */
let uniqueId = 0;

export const processControlData = (controlData: RecordAttributes[][]): Record<string, any>[] => {
  return controlData.map((recordSet: RecordAttributes[]) => {
    const processed = recordSet.reduce(
      (acc, record) => {
        if (record.name) {
          acc[record.name] = record.value ?? '';
        }
        return acc;
      },
      {} as Record<string, string>
    );
    // Add a unique ID
    processed.id = `row-${uniqueId++}`;
    return processed;
  });
};

/**
 * Clears the filter for a specific column while maintaining other active filters.
 *
 * @param gridApi - The grid API instance
 * @param column - The name of the column whose filter should be cleared
 * @param activeFilters - The current filter model containing all active filters
 * @returns The updated filter model after removing the specified column filter
 *
 * @example
 * const updatedFilters = clearFilter(gridApi, 'columnName', currentFilters)
 */
export const clearFilter = (gridApi: GridApi, column: string, activeFilters: FilterModel) => {
  const currentFilterModel = { ...activeFilters };
  delete currentFilterModel[column];

  // If there are remaining filters, ensure they maintain the multi-filter structure
  Object.keys(currentFilterModel).forEach((key) => {
    if (currentFilterModel[key] && !currentFilterModel[key].filterType) {
      currentFilterModel[key] = {
        filterType: 'multi',
        filterModels: [
          {
            filterType: 'text',
            ...currentFilterModel[key],
          },
        ],
      };
    }
  });

  gridApi.setFilterModel(currentFilterModel);
  return currentFilterModel;
};

/**
 * Clears all active filters from the grid.
 *
 * @param gridApi - The grid API instance
 * @returns An empty object representing a cleared filter model
 *
 * @example
 * const emptyFilters = clearAllFilters(gridApi)
 */
export const clearAllFilters = (gridApi: GridApi) => {
  gridApi.setFilterModel(null);
  return {};
};

// Method to save the Filter Preferences
export const saveUserPreferences = async (
  controlId: string,
  views: Record<string, UserViewPreferences> // Changed this type
): Promise<void> => {
  const input: UpdateUserPreferencesInput = {
    controlId,
    views,
  };

  try {
    await apiCall<void>({
      endpoint: `/UpdateUserPreferences/${controlId}`,
      method: 'PUT',
      body: input,
    });
  } catch (error) {
    console.error('Error in saveUserPreferences:', error);
    throw error;
  }
};

/**
 * Retrieves user preferences for a specific control
 * @param {string} controlId - The unique identifier for the control
 * @returns {Promise<GetUserPreferencesOutput>} - Promise resolving to user preferences
 */
export const getUserPreferences = async (controlId: string): Promise<GetUserPreferencesOutput> => {
  const options: ApiCallProps = {
    endpoint: `/GetUserPreferences/${controlId}`,
    method: 'GET',
  };
  return await apiCall<GetUserPreferencesOutput>(options);
};

// Custom component for showing Loading the data
export const CustomLoadingOverlay = () => {
  return (
    <div
      className="ag-overlay-loading-center"
      style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
    >
      <i className="fas fa-spinner fa-spin"></i>
      <span style={{ padding: '10px' }}>Loading data...</span>
    </div>
  );
};
