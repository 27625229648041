// src/utils/rumConfig.ts
import React from 'react';
import { useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { useUserAlias } from 'src/common/hooks/useUserAlias';
import { FeedbackEvent } from 'src/common/types/feedback';

const APPLICATION_ID = '1d690fa4-3c86-4bea-bc00-42780182f4f4';
const APPLICATION_VERSION = '1.0,0';
const GUEST_ROLE_ARN = 'arn:aws:iam::266735818235:role/ControllershipHub-RUM-pro-CognitoDefaultUnauthentic-pRaPH5wGDq9M';
const IDENTITY_POOL_ID = 'us-east-1:f358e8be-3d88-49db-a728-b0aa60733acc';
const RUM_ENDPOINT = 'https://dataplane.rum.us-east-1.amazonaws.com';
const APPLICATION_REGION: string = 'us-east-1';

let awsRum: AwsRum | null = null;

interface RUMProviderProps {
  children: JSX.Element;
}

/**
 * Initializes Amazon CloudWatch RUM (Real User Monitoring) for the application.
 * This function sets up real-time monitoring of user interactions, performance metrics,
 * and error tracking across the application.
 *
 * @param user - The Cognito user object for authenticated users, undefined for guests
 * @param alias - The user's alias/identifier used for session tracking
 *
 */
export const RUMProvider = ({ children }: RUMProviderProps): JSX.Element => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { alias } = useUserAlias();

  useEffect(() => {
    if (!awsRum) {
      initializeRUM(user as CognitoUser | undefined, alias);
    } else if (user) {
      awsRum.addSessionAttributes({
        isAuthenticated: 'true',
        userAlias: alias,
      });
    }
  }, [user, alias]);

  return <>{children}</>;
};

/**
 * Initializes Amazon CloudWatch RUM (Real User Monitoring) for the application
 *
 * @param user - The Cognito user object if authenticated, undefined if not
 * @param alias - The user's alias or identifier
 *
 * Configuration includes:
 * - Session tracking for both authenticated and guest users
 * - Secure cookie settings for user tracking
 * - Complete telemetry collection
 * - X-Ray tracing integration
 */
function initializeRUM(user: CognitoUser | undefined, alias: string) {
  const config: AwsRumConfig = {
    // Track user authentication status and alias
    sessionAttributes: {
      isAuthenticated: user ? 'true' : 'false',
      userAlias: alias || 'guest',
    },

    // Secure cookie configuration for user session tracking
    cookieAttributes: {
      domain: window.location.hostname, // Current domain only
      path: '/', // Available across all paths
      sameSite: 'Lax', // Moderate security for cross-site requests
      secure: true, // HTTPS only
      unique: true, // Unique identifier per user
    },

    // Monitoring configuration
    sessionSampleRate: 1, // Monitor 100% of sessions
    guestRoleArn: GUEST_ROLE_ARN, // IAM role for unauthenticated users
    identityPoolId: IDENTITY_POOL_ID, // Cognito Identity Pool for authentication
    endpoint: RUM_ENDPOINT, // RUM service endpoint

    // Enable all available telemetry types
    telemetries: [
      'performance', // Page load times, web vitals
      'errors', // JavaScript errors and exceptions
      'http', // API calls and responses
      'interaction', // User clicks and navigation
    ],

    allowCookies: true, // Enable cookie-based user tracking
    enableXRay: true, // Enable AWS X-Ray tracing integration
  };

  try {
    // Initialize RUM with application details and configuration
    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);

    // Capture unhandled promise rejections for error monitoring
    window.addEventListener('unhandledrejection', (event) => {
      awsRum?.recordError(event.reason);
    });
  } catch (error) {
    console.error('Failed to initialize CloudWatch RUM:', error);
  }
}

export const recordUserFeedback = (feedback: FeedbackEvent): void => {
  try {
    console.log(feedback);
    if (awsRum) {
      console.log('Inside awsRum');
      awsRum.recordEvent('com.amazon.rum.user_feedback_event', {
        feedbackType: feedback.feedbackType,
        userAlias: feedback.userAlias,
        page: feedback.page,
        timestamp: feedback.timestamp,
        value: 1,
      });
    }
  } catch (error) {
    console.error('Failed to record user feedback:', error);
  }
};

/**
 * Records AG Grid table load metrics to RUM
 * @param metrics Object containing grid performance metrics
 */
export const recordGridLoadMetrics = (metrics: {
  totalLoadTimeMs: number;
  gridLoadTimeMs: number;
  rowCount: number;
  columnCount: number;
  hasFilters: boolean;
  quickFilterActive: boolean;
  controlId: string;
  selectedView: string;
  pageUrl: string;
  timeToFirstByte: number;
}): void => {
  try {
    if (awsRum) {
      awsRum.recordEvent('ag_grid_table_load', {
        ...metrics,
        timestamp: new Date().toISOString(),
        performance: {
          timeToFirstByte: metrics.timeToFirstByte,
        },
      });
      console.log(
        `AG Grid Table Load Time: ${metrics.totalLoadTimeMs}ms (${(metrics.totalLoadTimeMs / 1000).toFixed(2)}s)`
      );
    }
  } catch (error) {
    console.error('Failed to record grid load metrics:', error);
  }
};

// Export helper functions to use throughout our application
export const recordCustomEvent = (name: string, data?: Record<string, any>): void => {
  try {
    if (awsRum) {
      if (data) {
        awsRum.recordEvent(name, data);
      } else {
        awsRum.recordEvent(name, {});
      }
    }
  } catch (error) {
    console.error('Failed to record custom event:', error);
  }
};

export const recordError = (error: Error): void => {
  try {
    awsRum?.recordError(error);
  } catch (err) {
    console.error('Failed to record error:', err);
  }
};

export const addSessionAttributes = (
  attributes: Record<string, string | number | boolean>
): void => {
  try {
    awsRum?.addSessionAttributes(attributes);
  } catch (error) {
    console.error('Failed to add session attributes:', error);
  }
};
