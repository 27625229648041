// ControlContext.tsx
import React, { createContext, useState, useEffect, useContext } from 'react';
import { ControlMetadata } from '@amzn/controllershiphub-typescript-client';
import { apiCall } from 'src/utils/ApiCall';
import { ApiPaths } from 'src/common/config/ApiConstants';
import { GetControlsResponse } from 'src/utils/ApiInterfaces';

/**
 * Define the shape of the ControlContext.
 * @typedef {Object} ControlContextType
 * @property {ControlMetadata[]} controls - An array of control metadata objects.
 * @property {boolean} loading - A flag indicating if data is being loaded.
 * @property {Error | null} error - An error object or null if no error occurred.
 */
export interface ControlContextType {
  controls: ControlMetadata[];
  loading: boolean;
  error: Error | null;
}

/**
 * Create the ControlContext using React.createContext.
 * @type {React.Context<ControlContextType | undefined>}
 */
const ControlContext = createContext<ControlContextType | undefined>(undefined);

/**
 * A React component that provides the ControlContext to its children.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render.
 * @returns {JSX.Element} The rendered component.
 */
export const ControlProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [controls, setControls] = useState<ControlMetadata[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    /**
     * Fetch controls data from the API.
     * @returns {Promise<void>}
     */
    const fetchControls = async () => {
      try {
        const response = await apiCall<GetControlsResponse>({
          endpoint: ApiPaths.getControls,
        });
        setControls(response.controls);
        setLoading(false);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error occurred'));
        setLoading(false);
      }
    };
    fetchControls();
  }, []);

  return (
    <ControlContext.Provider value={{ controls, loading, error }}>
      {children}
    </ControlContext.Provider>
  );
};

/**
 * A custom hook to consume the ControlContext.
 * @returns {ControlContextType} The ControlContext value.
 * @throws {Error} If the hook is used outside of a ControlProvider.
 */
export const useControls = (): ControlContextType => {
  const context = useContext(ControlContext);
  if (context === undefined) {
    throw new Error('useControls must be used within a ControlProvider');
  }
  return context;
};
