import React from 'react';

import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ControlProvider } from './common/ControlContext';
import { AuthProvider } from './provider/AuthProvider';
import { RUMProvider } from './provider/RumProvider';

import '@amzn/katal-components/styles.css';
import '@cloudscape-design/global-styles/index.css';

import './index.scss';

import './logger';
import { router } from './router/Router';
import AppInitWrapper from './components/AppInitWrapper';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const container = document.getElementById('root');

if (!container) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <AppInitWrapper>
      <AuthProvider>
        <RUMProvider>
          <ControlProvider>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
            </QueryClientProvider>
          </ControlProvider>
        </RUMProvider>
      </AuthProvider>
    </AppInitWrapper>
  </React.StrictMode>
);
