import { Box, Link } from '@cloudscape-design/components';
import { formatTimestamp } from 'src/utils/timeUtils';
import { AuditLogItem } from './interface';

interface AuditTrailValueCellProps {
  value: string;
}

export const AuditTrailValueCell = ({ value }: AuditTrailValueCellProps) => (
  <Box fontWeight="light" variant="code">
    {value || '-'}
  </Box>
);

export const columnDefinitions = (controlType: string) => [
  {
    id: 'controlCode',
    header: `${controlType}`,
    cell: (item: AuditLogItem) => item.controlCode,
    width: 100,
  },
  {
    id: 'timestamp',
    header: 'Timestamp',
    cell: (item: AuditLogItem) => formatTimestamp(item.modifiedAt),
    width: 160,
  },
  {
    id: 'modifiedBy',
    header: 'Modified By',
    cell: (item: AuditLogItem) => (
      <Link
        href={`https://phonetool.amazon.com/users/${item.modifiedBy}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {item.modifiedBy}
      </Link>
    ),
    width: 120,
  },
  {
    id: 'field',
    header: 'Modified Field',
    cell: (item: AuditLogItem) => <Box fontSize="body-s">{item.field}</Box>,
    width: 150,
  },
  {
    id: 'oldValue',
    header: 'Old Value',
    cell: (item: AuditLogItem) => <AuditTrailValueCell value={item.oldValue} />,
    width: 200,
  },
  {
    id: 'newValue',
    header: 'New Value',
    cell: (item: AuditLogItem) => <AuditTrailValueCell value={item.newValue} />,
    width: 200,
  },
];
