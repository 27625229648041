import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import {
  Button,
  SpaceBetween,
  Box,
  Grid,
  ButtonDropdown,
  Modal,
} from '@cloudscape-design/components';
import { AgGridReact } from 'ag-grid-react';
import { initializeAgGridLicense } from 'src/utils/License';
import {
  GridApi,
  CellValueChangedEvent,
  GridReadyEvent,
  FilterChangedEvent,
  FilterModel,
} from 'ag-grid-community';
import { CustomCellPopoverRenderer } from 'src/common/CustomCellPopoverRenderer';
import {
  handleSaveChanges,
  processControlData,
  clearFilter,
  clearAllFilters,
  saveUserPreferences,
  createAutoPopulateConfigs,
  handleAutoPopulate,
  getUserPreferences,
  CustomLoadingOverlay,
} from 'src/utils/ControlSummaryUtils';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import {
  ControlAttributesMetadata,
  RecordAttributes,
  UserViewPreferences,
} from '@amzn/controllershiphub-typescript-client';
import { ActiveFilters } from 'src/common/ActiveFilter';
import { FilterSet } from 'src/common/FilterSet';
import { useControls } from 'src/common/ControlContext';
import { getButtonDropdownItems } from 'src/common/ControlSummaryTableConfig';
import { recordGridLoadMetrics } from 'src/provider/RumProvider';
import { createColumnDefs, createGridOptions } from 'src/utils/GridUtils';
import { ModuleRegistry, AllCommunityModule } from 'ag-grid-community';
import { useUserAlias } from 'src/common/hooks/useUserAlias';
import {
  ColumnMenuModule,
  ColumnsToolPanelModule,
  ContextMenuModule,
  FiltersToolPanelModule,
  SetFilterModule,
  MultiFilterModule,
  SideBarModule,
  SideBarDef,
  CellSelectionModule,
  ClipboardModule,
} from 'ag-grid-enterprise';
import { useLocation } from 'react-router-dom';
const DEFAULT_VIEW = 'Default View' as const;

initializeAgGridLicense();
ModuleRegistry.registerModules([
  AllCommunityModule,
  MultiFilterModule,
  SetFilterModule,
  FiltersToolPanelModule,
  SideBarModule,
  ColumnMenuModule,
  ContextMenuModule,
  ColumnsToolPanelModule,
  CellSelectionModule,
  ClipboardModule,
]);

interface TableUtilProps {
  controlData: RecordAttributes[][];
  controlAttributesData: ControlAttributesMetadata | null;
  isLoading: boolean;
  controlId: string;
}

/**
 * ControlSummaryPageAGGrid
 *
 * This component renders a data grid using the AG Grid library to display and manage control summary data.
 * It provides features like filtering, sorting, editing, and saving changes to the control data.
 *
 * @param {Object} props - The component props
 * @param {RecordAttributes[][]} props.controlData - The control data to be displayed in the grid
 * @param {ControlAttributesMetadata | null} props.controlAttributesData - Metadata for the control attributes
 * @param {boolean} props.isLoading - Indicates whether the data is currently being loaded
 * @param {string} props.controlId - The unique identifier for the control
 */
export const ControlSummaryPageAGGrid: React.FC<TableUtilProps> = ({
  controlData,
  controlAttributesData,
  isLoading,
  controlId,
}: {
  controlData: RecordAttributes[][];
  controlAttributesData: ControlAttributesMetadata | null;
  isLoading: boolean;
  controlId: string;
}) => {
  const [rowData, setRowData] = useState<Record<string, any>[]>([]);
  const [editedCells, setEditedCells] = useState<Set<string>>(new Set());
  const [activeFilters, setActiveFilters] = useState<FilterModel>({});
  const [quickFilterText, setQuickFilterText] = useState('');
  const [selectedView, setSelectedView] = useState<string>(DEFAULT_VIEW);
  const gridApiRef = useRef<GridApi | null>(null);
  const { controls } = useControls();
  const { alias } = useUserAlias();
  const defaultFiltersAppliedRef = useRef(false);
  const [filterViews, setFilterViews] = useState<Record<string, UserViewPreferences>>({});
  const [isLoadingFilterViews, setIsLoadingFilterViews] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<(() => void) | null>(null);
  const location = useLocation();
  const gridLoadStartTime = useRef<number>(0);
  // Add a state to track saving status
  const [isSaving, setIsSaving] = useState(false);

  const control = useMemo(
    () => controls.find((c) => c.controlId === controlId) || null,
    [controls, controlId]
  );
  const dropdownItems = useMemo(() => getButtonDropdownItems(control), [control]);

  const columnDefs = useMemo(
    () => createColumnDefs(controlAttributesData, editedCells, CustomCellPopoverRenderer),
    [controlAttributesData, editedCells, CustomCellPopoverRenderer]
  );

  // Handle unsaved changes when navigating away
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (editedCells.size > 0) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    const handlePopState = (e: PopStateEvent) => {
      if (editedCells.size > 0) {
        e.preventDefault();
        setShowUnsavedChangesModal(true);
        setPendingNavigation(() => () => {
          window.history.pushState(null, '', e.state?.url || '/');
        });
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [editedCells]);

  // Record RUM metrics when loading completes
  useEffect(() => {
    if (!isLoading) {
      const loadTime = Date.now() - gridLoadStartTime.current;
      const gridLoadTime = gridLoadStartTime.current ? Date.now() - gridLoadStartTime.current : 0;
      // Record grid load metrics using the dedicated RUM function
      recordGridLoadMetrics({
        totalLoadTimeMs: loadTime,
        gridLoadTimeMs: gridLoadTime,
        rowCount: rowData.length,
        columnCount: columnDefs.length,
        hasFilters: Object.keys(activeFilters).length > 0,
        quickFilterActive: !!quickFilterText,
        controlId,
        selectedView,
        pageUrl: location.pathname,
        timeToFirstByte: performance.now() - gridLoadStartTime.current,
      });
    }
  }, [isLoading]);
  const handleUnsavedChangesConfirm = () => {
    setShowUnsavedChangesModal(false);
    if (pendingNavigation) {
      pendingNavigation();
      setPendingNavigation(null);
    }
  };

  const handleUnsavedChangesCancel = () => {
    setShowUnsavedChangesModal(false);
    setPendingNavigation(null);
    // Push the current location back to the history to prevent navigation
    window.history.pushState(null, '', location.pathname);
  };

  /**
   * Initialize and load filter views
   */
  useEffect(() => {
    const fetchAndCombineFilters = async () => {
      setIsLoadingFilterViews(true);
      try {
        const response = await getUserPreferences(controlId);
        let combinedViews: Record<string, UserViewPreferences> = response.views || {};

        if (controlAttributesData?.defaultFilters) {
          try {
            const defaultConfig = JSON.parse(controlAttributesData.defaultFilters);
            // Create default view with both column state and filters
            const defaultView: UserViewPreferences = {
              columnPreferences: JSON.stringify(defaultConfig.columnState || []),
              filterPreferences: defaultConfig.filters || {},
            };
            combinedViews = {
              [DEFAULT_VIEW]: defaultView,
              ...combinedViews,
            };

            // Apply default configuration when loading for the first time
            if (gridApiRef.current) {
              // Apply default filters
              if (defaultConfig.filters) {
                gridApiRef.current.setFilterModel(defaultConfig.filters);
                setActiveFilters(defaultConfig.filters);
              }

              // Apply default column state
              if (defaultConfig.columnState) {
                gridApiRef.current.applyColumnState({
                  state: defaultConfig.columnState,
                  applyOrder: true,
                });
              }
            }
          } catch (error) {
            console.error('Error parsing default filters:', error);
          }
        }

        setFilterViews(combinedViews);
        // Ensure Default View is selected initially
        setSelectedView(DEFAULT_VIEW);
      } catch (error) {
        console.error('Error loading filter views:', error);
      } finally {
        setIsLoadingFilterViews(false);
      }
    };
    fetchAndCombineFilters();
  }, [controlId, controlAttributesData]);

  /**
   * Callback handler when a filter set is selected
   */
  const handleFilterViewSelect = useCallback(
    (viewName: string) => {
      if (!gridApiRef.current || !filterViews[viewName]) return;

      const selectedView = filterViews[viewName];

      try {
        // Apply column preferences
        if (selectedView.columnPreferences) {
          const columnState = JSON.parse(selectedView.columnPreferences);
          gridApiRef.current.applyColumnState({ state: columnState, applyOrder: true });
        }

        // Clear all existing filters first
        gridApiRef.current.setFilterModel(null);

        // Apply filter preferences
        if (selectedView.filterPreferences) {
          Object.entries(selectedView.filterPreferences).forEach(([colId, filterModel]) => {
            gridApiRef.current!.setColumnFilterModel(colId, filterModel);
          });
        }

        gridApiRef.current.onFilterChanged();
        setSelectedView(viewName);
      } catch (error) {
        console.error('Error applying view:', error);
      }
    },
    [filterViews]
  );

  // Configure Default columns
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              filterOptions: [
                'contains',
                'notContains',
                'equals',
                'notEqual',
                'startsWith',
                'endsWith',
              ],
              defaultOption: 'contains',
              maxNumConditions: 1,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      },
    }),
    []
  );

  const autoPopulateConfigs = useMemo(() => createAutoPopulateConfigs(), []);

  const [originalValues, setOriginalValues] = useState<Record<string, any>>({});

  // When grid data is loaded, store the original values
  useEffect(() => {
    const processed = processControlData(controlData);
    setRowData(processed);

    // Store original values
    const originals: Record<string, any> = {};
    if (processed && Array.isArray(processed)) {
      processed.forEach((row: any) => {
        if (row && row.id) {
          Object.entries(row).forEach(([key, value]) => {
            originals[`${row.id}-${key}`] = value ?? '';
          });
        }
      });
    }
    setOriginalValues(originals);
  }, [controlData]);

  // Modified onCellValueChanged handler
  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent) => {
      if (!event.node) return;

      const rowId = event.node.id;
      const colId = event.column.getColId();
      const cellId = `${rowId}-${colId}`;

      // Get the original and new values
      const originalValue = originalValues[cellId];
      const newValue = event.newValue;

      // Determine if the current column is a checkbox type column
      const isCheckboxColumn = event.column.getColDef().cellRenderer === 'agCheckboxCellRenderer';

      // Compare original and new values to detect changes
      let areValuesEqual;
      if (isCheckboxColumn) {
        // For checkbox columns: Convert both values to boolean for consistent comparison
        // This handles cases where values might be stored as strings ('true'/'false') or actual booleans
        const originalBooleanValue = originalValue === true || originalValue === 'true';
        const newBooleanValue = newValue === true || newValue === 'true';
        areValuesEqual = originalBooleanValue === newBooleanValue;
      } else {
        // For non-checkbox columns: Direct value comparison
        areValuesEqual = originalValue === newValue;
      }

      // If values match, remove from editedCells
      if (areValuesEqual) {
        setEditedCells((prev) => {
          const newSet = new Set(prev);
          newSet.delete(cellId);
          return newSet;
        });

        // Update the grid cell style
        if (gridApiRef.current) {
          gridApiRef.current.refreshCells({
            force: true,
            columns: [colId],
            rowNodes: [event.node],
          });
        }
      } else {
        // If the value is different from original, add to editedCells
        setEditedCells((prev) => new Set(prev).add(cellId));
      }

      handleAutoPopulate({
        event,
        configs: autoPopulateConfigs,
        alias,
      });
    },
    [autoPopulateConfigs, alias, originalValues, gridApiRef]
  );

  // Handles saving all changes made to the grid data
  // Clears the edited cells tracking after successful save
  // Displays success/error messages to the user
  const saveChanges = useCallback(async () => {
    try {
      setIsSaving(true); // Set saving status to true
      if (!gridApiRef.current) {
        throw new Error('Grid API not initialized');
      }

      const updatedData = await handleSaveChanges(
        rowData,
        editedCells,
        controlAttributesData,
        controlId,
        originalValues // Pass originalValues here
      );

      // Only proceed if there were actual changes
      if (updatedData) {
        // Convert string boolean values to actual booleans for grid display
        // This ensures proper checkbox behavior in the grid while maintaining data consistency
        const normalizedGridData = updatedData.map((row) => {
          const normalizedRow = { ...row };
          Object.entries(row).forEach(([fieldName, fieldValue]) => {
            if (fieldValue === 'true' || fieldValue === 'false') {
              normalizedRow[fieldName] = fieldValue === 'true';
            }
          });
          return normalizedRow;
        });

        // Update grid state with normalized data
        setRowData(normalizedGridData);
        setEditedCells(new Set()); // Reset edited cells tracking

        // Build new reference values for future edit comparisons
        // Creates a map of cellId -> value for tracking changes
        const referenceValues: Record<string, any> = {};
        normalizedGridData.forEach((row) => {
          if (row?.id) {
            Object.entries(row).forEach(([fieldName, fieldValue]) => {
              referenceValues[`${row.id}-${fieldName}`] = fieldValue;
            });
          }
        });
        setOriginalValues(referenceValues);
        alert('Changes saved successfully!');
      } else {
        alert('No changes to save');
      }
    } catch (error) {
      console.error('Error saving changes:', error);
      alert(error instanceof Error ? error.message : 'Failed to save changes. Please try again.');
    } finally {
      setIsSaving(false);
    }
  }, [rowData, controlId, controlAttributesData, editedCells, originalValues]);

  // Initializes the grid when it's ready
  // Stores the grid API reference and resets the default filters flag
  // Shows initial loading overlay
  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    gridLoadStartTime.current = Date.now();
    defaultFiltersAppliedRef.current = false;
  }, []);

  // Clears filters for a specific column
  // Updates the active filters state with the new filter model
  const handleClearFilter = useCallback(
    (column: string) => {
      if (gridApiRef.current) {
        const newFilters = clearFilter(gridApiRef.current, column, activeFilters);
        setActiveFilters(newFilters);
      }
    },
    [activeFilters]
  );

  //Callback handler to save a filterset
  const handleSaveFilterSet = useCallback(
    async (filterSetName: string): Promise<string> => {
      if (!gridApiRef.current) {
        throw new Error('Grid not initialized');
      }

      try {
        const currentFilterModel = gridApiRef.current.getFilterModel();
        const currentColumnState = gridApiRef.current.getColumnState();

        // Create new views object excluding Default View
        const updatedViews: Record<string, UserViewPreferences> = {};
        Object.entries(filterViews).forEach(([key, value]) => {
          if (key !== DEFAULT_VIEW) {
            updatedViews[key] = value;
          }
        });

        // Create new view preference with both filter and column preferences
        updatedViews[filterSetName] = {
          filterPreferences: currentFilterModel,
          columnPreferences: JSON.stringify(currentColumnState),
        };

        // Save to backend
        await saveUserPreferences(controlId, updatedViews);

        // Update local state
        setFilterViews({
          ...(filterViews[DEFAULT_VIEW] ? { [DEFAULT_VIEW]: filterViews[DEFAULT_VIEW] } : {}),
          ...updatedViews,
        });

        return filterSetName;
      } catch (error) {
        console.error('Error saving view:', error);
        throw new Error('Failed to save view');
      }
    },
    [controlId, filterViews]
  );

  // Clears all active filters from the grid
  // Resets the default filters applied flag
  const handleClearAllFilters = useCallback(() => {
    if (gridApiRef.current) {
      const newFilters = clearAllFilters(gridApiRef.current);
      setActiveFilters(newFilters);
      setSelectedView('Select View'); // Reset to Select View when clearing filters
    }
  }, []);

  // Updates the active filters state whenever filters change in the grid
  const onFilterChanged = useCallback((event: FilterChangedEvent) => {
    const filterModel = event.api.getFilterModel();
    // Add displayName to each filter from column definitions
    const filterModelWithDisplayNames = Object.entries(filterModel).reduce((acc, [key, value]) => {
      const column = event.api.getColumnDef(key);
      return {
        ...acc,
        [key]: { ...value, displayName: column?.headerName || key },
      };
    }, {});
    setActiveFilters(filterModelWithDisplayNames);
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    const filterValue = (document.getElementById('filter-text-box') as HTMLInputElement).value;
    setQuickFilterText(filterValue);
    gridApiRef.current!.setGridOption('quickFilterText', filterValue);
  }, []);

  // Configure options passed on to the grid
  const gridOptions = useMemo(
    () => ({
      ...createGridOptions(defaultColDef, rowData, columnDefs, onCellValueChanged, onGridReady),
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: false,
              suppressColumnSelectAll: false,
            },
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressFilterSearch: false,
            },
          },
        ],
        defaultToolPanel: '',
        position: 'left', // explicitly type the position
      } as SideBarDef,
      loading: isLoading,
      loadingOverlayComponent: CustomLoadingOverlay,
      rowBuffer: 100, // Number of rows to render outside visible area
      maxBlocksInCache: 10, // Maximum blocks to cache
      onFilterChanged: onFilterChanged,
    }),
    [
      defaultColDef,
      rowData,
      columnDefs,
      onCellValueChanged,
      onGridReady,
      onFilterChanged,
      CustomCellPopoverRenderer,
      isLoading,
    ]
  );

  const onBtnExport = useCallback(() => {
    gridApiRef?.current?.exportDataAsCsv();
  }, []);
  return (
    <div data-testid="control-summary-page">
      <Modal
        data-testid="modal"
        visible={showUnsavedChangesModal}
        onDismiss={handleUnsavedChangesCancel}
        header="Unsaved Changes"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={handleUnsavedChangesCancel}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleUnsavedChangesConfirm}>
                Leave without saving
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        You have unsaved changes. Are you sure you want to leave this page?
      </Modal>
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '0.5rem',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <FilterSet
              filterViews={filterViews}
              onFilterSetSelect={handleFilterViewSelect}
              loading={isLoadingFilterViews}
              selectedView={selectedView}
            />
            <input
              type="text"
              placeholder="Search here"
              id="filter-text-box"
              onInput={onFilterTextBoxChanged}
              style={{
                width: '100%',
                maxWidth: '300px',
                padding: '8px',
                fontSize: '14px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                marginLeft: '1rem',
              }}
            />
          </div>
          <div>
            <Button variant="inline-icon" iconName="download" onClick={onBtnExport}>
              Export
            </Button>
            <Box margin={{ left: 'xs' }} display="inline-block" />
            <ButtonDropdown items={dropdownItems}>References</ButtonDropdown>
            <Box margin={{ left: 'xs' }} display="inline-block" />
            <Button variant="primary" onClick={saveChanges} disabled={isSaving}>
              {isSaving ? 'Saving...' : 'Save Changes'}
            </Button>
          </div>
        </div>
        <ActiveFilters
          filters={activeFilters}
          onClearFilter={handleClearFilter}
          onClearAllFilters={handleClearAllFilters}
          onSaveFilterSet={handleSaveFilterSet}
        />
      </div>
      <div className="ag-theme-quartz">
        <AgGridReact {...gridOptions} />
      </div>
    </div>
  );
};
