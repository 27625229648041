import React from 'react';

import { Box, SpaceBetween } from '@cloudscape-design/components';
import bannerImage from '../../../images/EmptyBanner.svg';
import styles from './styles.module.scss';
interface EmptyStateProps {
  icon?: React.ReactNode;
  title: string;
  verticalCenter?: boolean;
  description: string;
  action?: React.ReactNode;
}

const EmptyState = ({ icon, title, description, action, verticalCenter }: EmptyStateProps) => (
  <div className={verticalCenter ? styles.verticalCenter : ''}>
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="text-body-secondary">
      <SpaceBetween size="xxs" direction="vertical">
        <div>
          {icon ? (
            <div>{icon}</div>
          ) : (
            <div>
              <img src={bannerImage} alt="empty" />
            </div>
          )}
          <Box variant="strong" color="inherit">
            {title}
          </Box>
          <Box variant="p" color="inherit">
            {description}
          </Box>
        </div>
        {action}
      </SpaceBetween>
    </Box>
  </div>
);

export default EmptyState;
