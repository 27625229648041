import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Button,
  Header,
  Pagination,
  Table,
  TableProps,
  TextFilter,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from 'src/common/Breadcrumbs';
import { NavigationPaths } from 'src/common/config/ApiConstants';
import { useControls } from 'src/common/ControlContext';
import { apiCall } from 'src/utils/ApiCall';
import PageLayout from '../common/PageLayout/PageLayout';

interface FileItem {
  id: string;
  fileName: string;
  filePath: string;
  fileType: string;
}

interface ApiResponse {
  filePaths: string[];
}

/**
 * HistoricalExecutionsPage Component
 * Displays and manages historical execution data for a specific control
 * in the Controllership Hub application.
 */
export const HistoricalExecutionsPage = () => {
  const { controls } = useControls();
  const [fileItems, setFileItems] = useState<FileItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { controlId } = useParams<{ controlId: string }>();
  const control = controls.find((control) => control.controlId === controlId);
  const safeControlId = controlId ?? '';

  useEffect(() => {
    const fetchFilePaths = async () => {
      try {
        const data = await apiCall<ApiResponse>({
          endpoint: `/GetHistoricalExecutionData/${safeControlId}`,
        });
        const items: FileItem[] = data.filePaths.map((filePath: string, index: number) => ({
          id: `file-${index}`,
          fileName: getFileName(filePath),
          filePath: filePath,
          fileType: getFileType(filePath),
        }));
        setFileItems(items);
      } catch (error) {
        console.error('Error fetching file paths:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFilePaths();
  }, []);

  const handleDownload = async (filePath: string) => {
    try {
      const response = await apiCall<{ presignedUrl: string }>({
        endpoint: `/GetHistoricalExecutionData/${safeControlId}`,
        method: 'GET',
        queryParams: {
          filePath: filePath,
        },
      });
      const presignedUrl = response.presignedUrl;
      // Create a hidden anchor element
      const link = document.createElement('a');
      link.href = presignedUrl;
      link.style.display = 'none';
      document.body.appendChild(link);
      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error getting presigned URL:', error);
    }
  };

  const getMatchesCountText = (count: any) => {
    return count === 1 ? `1 match` : `${count} matches`;
  };

  const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: any) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page',
  };

  const columnDefinitions = [
    {
      id: 'fileName',
      header: 'File Name',
      cell: (item) => item.fileName,
      sortingField: 'fileName',
    },
    {
      id: 'fileType',
      header: 'File Type',
      cell: (item) => item.fileType,
      sortingField: 'fileType',
    },
    {
      id: 'action',
      header: 'Action',
      cell: (item: { filePath: string }) => (
        <Button onClick={() => handleDownload(item.filePath)}>Download</Button>
      ),
    },
  ] as TableProps.ColumnDefinition<FileItem>[];

  const getFileName = (filePath: string) => {
    return filePath.split('/').pop() || '';
  };

  const getFileType = (filePath: string) => {
    const extension = filePath.split('.').pop()?.toUpperCase() || '';
    return extension;
  };

  const {
    items: collectionItems,
    filterProps,
    paginationProps,
    filteredItemsCount,
    collectionProps,
  } = useCollection(fileItems, {
    filtering: {
      empty: 'No files match the filters',
      noMatch: 'No files match the filters',
    },
    pagination: { pageSize: 10 },
    sorting: {},
  });

  return (
    <PageLayout
      header={
        <Header variant="h1">
          {control?.controlName} - {control?.controlSubTitle}
        </Header>
      }
    >
      {location.pathname !== '/' && safeControlId !== null ? (
        <Breadcrumbs
          items={[
            { text: 'Controllership Hub', href: '/' },
            {
              text: `Control Home`,
              href: `/${control?.controlType?.toLowerCase()}/${encodeURIComponent(safeControlId)}`,
            },
            {
              text: `Control Data`,
              href: `/${control?.controlType?.toLowerCase()}/${encodeURIComponent(safeControlId)}${
                NavigationPaths.summary
              }`,
            },
            { text: `Historical Executions`, href: '' },
          ]}
        />
      ) : undefined}
      <Table
        {...collectionProps}
        header={
          <Header counter={`(${fileItems.length})`}>GRC 5257 Quarterly certification files</Header>
        }
        columnDefinitions={columnDefinitions}
        items={collectionItems}
        loading={isLoading}
        loadingText="Loading files"
        trackBy="id"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No files</b>
            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
              No files to display.
            </Box>
          </Box>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringAriaLabel="Filter files"
          />
        }
      />
    </PageLayout>
  );
};
