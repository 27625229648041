import { AuditLog } from '@amzn/controllershiphub-typescript-client';
import { AuditLogItem } from './interface';

/**
 * - Flattens the audit changes into list of AuditLogItem
 * -
 * @param auditLogs
 * @returns AuditLogItem[]
 */
export const flattenAuditLogs = (auditLogs: AuditLog[]): AuditLogItem[] => {
  const result = auditLogs
    .flatMap(
      (auditLog: AuditLog) =>
        auditLog.changes?.map((change) => {
          if (!auditLog.controlCode) {
            return null;
          }
          return {
            controlCode: auditLog.controlCode || '',
            controlId: auditLog.controlId || '',
            modifiedAt: auditLog.modifiedAt,
            modifiedBy: auditLog.modifiedBy || '',
            field: change.field || '',
            oldValue: change.oldValue || '',
            newValue: change.newValue || '',
          };
        }) || []
    )
    .filter((item): item is AuditLogItem => item !== null && item !== undefined);

  return result;
};
