import { ControlMetadata } from '@amzn/controllershiphub-typescript-client';
import {
  Alert,
  Button,
  Container,
  Flashbar,
  Grid,
  SpaceBetween,
  Spinner,
} from '@cloudscape-design/components';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs } from 'src/common/Breadcrumbs';
import { NavigationPaths } from 'src/common/config/ApiConstants';
import { useControls } from 'src/common/ControlContext';
import { useUserAlias } from 'src/common/hooks/useUserAlias';
import { PageHeader } from 'src/components/layout/PageHeader';
import ControlDescription from 'src/utils/ControlDescription';
import PageLayout from 'src/components/common/PageLayout/PageLayout';

/**
 * ControlHomePage Component
 *
 * This is a generic component that generates a Control Home page for any control
 * within the Controllership Hub. It dynamically renders content based on the
 * Control ID provided in the URL.
 *
 * The component utilizes custom hooks to retrieve the user alias and the complete
 * list of available controls. It then identifies and displays the specific control
 * information by matching the Control ID from the URL parameters with the control
 * list.
 *
 * This approach allows for a single, reusable component to handle the display of
 * any control's home page, enhancing maintainability and consistency across the
 * application.
 */
export const ControlHomePage = () => {
  // Get the user alias using a custom hook
  const { alias } = useUserAlias();

  // Get the list of controls using a custom hook
  const { controls } = useControls();

  // Extract the controlId from the URL parameters
  const { controlId } = useParams<{ controlId: string }>();

  /**
   * Memoized function to find the current control
   *
   * This function searches for a control in the controls array that matches the controlId from the URL.
   * It returns the found control object or null if no match is found.
   * The function is memoized to optimize performance, only recalculating when controls or controlId change.
   */
  const control = useMemo(() => {
    return controls.find((c) => c.controlId === controlId) || null;
  }, [controls, controlId]);

  // Import the useNavigate hook from react-router-dom (assumed)
  const navigate = useNavigate();

  /**
   * Handles the click event for a control button.
   *
   * @param {ControlMetadata | null} control - The metadata of the clicked control, or null if no control is associated.
   */
  const handleButtonClick = (control: ControlMetadata | null) => {
    // Check if the control has an external link
    if (control?.externalLink) {
      // If external link exists, open it in a new tab with security attributes
      window.open(control.externalLink, '_blank', 'noopener,noreferrer');
    } else if (control) {
      // If control exists but doesn't have an external link, navigate internally
      // Format the control type: convert to lowercase and replace underscores
      const controlType = control.controlType?.toLowerCase().replace(/_/g, '_');

      // Get the current URL
      window.location.href = `/${controlType}/${control.controlId}${NavigationPaths.summary}`;
    } else {
      // If control is null, navigate to the home page as a fallback
      window.location.href = '/';
    }
  };

  return (
    <>
      {/* Display error message when user is not authorized and control is onboarded in CHub */}
      {control && !control.userAuthorized && !control?.externalLink && (
        <Flashbar
          items={[
            {
              type: 'error',
              header: 'Access Denied',
              content:
                'You are not authorized to access this control. ' +
                'Please contact your administrator to request Permission',
              dismissible: false,
              id: 'unauthorized-access',
            },
          ]}
        />
      )}
      <PageLayout
        defaultPadding={true}
        header={
          <PageHeader
            title="Controllership Hub"
            subtitle="Your one-stop location for consolidated and streamlined controllership work within the WW Amazon Stores Finance."
            description="Finance Controllership Hub brings together the disparate controllership tools, streamlining the once-fragmented world of controllership into a unified and efficient ecosystem. It brings ease, efficiency, and convenience to controllership monitoring, reporting, and task tracking within World Wide Amazon Stores Finance. It is designed to transform how finance controllership processes are managed, monitored, and automated."
          />
        }
      >
        {/* Breadcrumbs - Only shown when not on the home page */}
        {location.pathname !== '/' ? (
          <Breadcrumbs
            items={[
              { text: 'Controllership Hub', href: '/' },
              { text: `${control?.controlName}`, href: '' },
            ]}
          />
        ) : undefined}
        {controls.length > 0 ? (
          <Container>
            <h1>
              <b>{control?.controlName || 'No control name'}</b>
            </h1>
            <h2>{control?.controlSubTitle || 'No control SubTitle'}</h2>
            <h3 data-testid="control-description">
              <ControlDescription description={control?.controlDescription || ''} />
            </h3>
            <SpaceBetween size="m">
              {/* Control Disclaimer - Shown if available */}
              {control?.controlDisclaimer && (
                <Alert statusIconAriaLabel="Warning" type="warning">
                  {control?.controlDisclaimer}
                </Alert>
              )}
              <Grid>
                {/* Perform Control Execution Button */}
                <Button
                  variant="primary"
                  iconAlign="right"
                  disabled={!control?.userAuthorized && !control?.externalLink}
                  onClick={() => handleButtonClick(control)}
                  {...(control?.externalLink
                    ? {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                        iconName: 'external',
                      }
                    : {})}
                >
                  Perform {control?.controlName || 'No control name'}
                </Button>
              </Grid>
            </SpaceBetween>
          </Container>
        ) : (
          // Loading spinner shown when controls are not yet loaded
          <Spinner />
        )}
      </PageLayout>
    </>
  );
};
