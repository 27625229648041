import {
  GetControlAuditLogsCommandInput,
  GetControlCodeAuditLogsCommandInput,
} from '@amzn/controllershiphub-typescript-client';
import { useQuery } from '@tanstack/react-query';
import controllershipHub from '../clients/chub-client';
import { handleApiRequest } from '../utils/apiRequest';
import { queryKeys } from '../utils/queryKeys';
import { CACHE_TIME, STALE_TIME } from 'src/constants';

export function useControlAuditLogs(input: GetControlAuditLogsCommandInput) {
  return useQuery({
    queryKey: queryKeys.audits.control(input.controlId),
    queryFn: () => handleApiRequest(() => controllershipHub.getControlAuditLogs(input)),
    enabled: !!input.controlId,
    staleTime: STALE_TIME,
    gcTime: CACHE_TIME,
  });
}

export function useControlCodeAuditLogs(input: GetControlCodeAuditLogsCommandInput) {
  return useQuery({
    queryKey: queryKeys.audits.code(input.controlId),
    queryFn: () => handleApiRequest(() => controllershipHub.getControlCodeAuditLogs(input)),
    enabled: !!input.controlId,
    staleTime: STALE_TIME,
    gcTime: CACHE_TIME,
  });
}
