import { getCurrentStage, getBaseUrl, ApiError } from '../common/config/ApiConstants';
import { Auth } from 'aws-amplify';

/**
 * Interface defining the properties for an API call.
 * @interface ApiCallProps
 * @property {string} endpoint - The API endpoint to call.
 * @property {'GET' | 'POST' | 'PUT' | 'PATCH'} [method='GET'] - The HTTP method to use for the API call.
 * @property {object} [body] - The request body for POST, PUT, or PATCH requests.
 * @property {Record<string, any>} [queryParams] - Query parameters to append to the URL.
 */
export interface ApiCallProps {
  endpoint: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH';
  body?: object;
  queryParams?: Record<string, any>;
}

/**
 * Makes an API call with the given parameters.
 * @template T - The expected type of the API response.
 * @param {ApiCallProps} options - The options for the API call.
 * @param {string} options.endpoint - The API endpoint to call.
 * @param {'GET' | 'POST' | 'PUT' | 'PATCH'} [options.method='GET'] - The HTTP method to use.
 * @param {object} [options.body] - The request body for POST, PUT, or PATCH requests.
 * @param {Record<string, any>} [options.queryParams] - Query parameters to append to the URL.
 * @returns {Promise<T>} A promise that resolves to the API response of type T.
 * @throws {ApiError} If the API call fails or returns a non-OK status.
 */
export const apiCall = async <T>({
  endpoint,
  method = 'GET',
  body,
  queryParams,
}: ApiCallProps): Promise<T> => {
  try {
    const stage = getCurrentStage();
    const baseUrl = getBaseUrl(stage);

    // Construct URL with query parameters
    const url = new URL(`${baseUrl}${endpoint}`);
    if (queryParams) {
      Object.entries(queryParams).forEach(([key, value]) => {
        url.searchParams.append(key, value);
      });
    }

    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: body ? JSON.stringify(body) : undefined,
    });

    if (response.status === 401) {
      throw response;
    }

    if (!response.ok) {
      throw new Error('An error occurred during the API call');
    }

    // For methods that typically don't return content (PUT, DELETE)
    // or when the response is empty, return undefined
    if (response.status === 204 || response.headers.get('content-length') === '0') {
      return undefined as T;
    }

    // Try to parse JSON only if there's content
    const text = await response.text();
    if (!text) {
      return undefined as T;
    }

    try {
      return JSON.parse(text) as T;
    } catch {
      // If JSON parsing fails but the request was successful,
      // return undefined for void operations
      return undefined as T;
    }
  } catch (error) {
    console.error('API call error:', error);
    if (error instanceof Response) {
      throw new ApiError(error.status, `API call failed with status ${error.status}`);
    } else if (error instanceof Error) {
      throw new ApiError(500, error.message);
    } else {
      throw new ApiError(500, 'An unknown error occurred during the API call');
    }
  }
};
