import { ActionFunction, LoaderFunction } from 'react-router-dom';

export type RouteKeys = typeof ROUTES;

export interface RouteConfig {
  path?: string;
  children?: RouteConfig[];
  index?: boolean;
  element: React.ReactNode;
  errorElement?: React.ReactNode;
  loader?: LoaderFunction;
  action?: ActionFunction;
}

export const ROUTES = {
  HOME: '/',
  CONTROL: {
    BASE: '/:controlType/:controlId',
    SUMMARY: '/:controlType/:controlId/summary',
    HISTORICAL: '/:controlType/:controlId/historical',
  },
  REPORTS: {
    BASE: '/reports',
    DETAIL: '/reports/:controlId',
  },
} as const;
