import { ControllershipHubBackendClientConfig } from '@amzn/controllershiphub-typescript-client';
import { AWS_REGION, API_DOMAIN } from 'src/constants/api';

export type Environment = 'prod' | 'beta' | 'gamma' | 'dev';

export const DEFAULT_CLIENT_CONFIG: Partial<ControllershipHubBackendClientConfig> = {
  region: AWS_REGION,
  maxAttempts: 3,
};

export function constructApiUrl(env: Environment | string): string {
  return `https://${env}.chub-api.${API_DOMAIN}`;
}

export function getCurrentEnvironment(): Environment | string {
  if (typeof window === 'undefined') {
    return 'beta';
  }

  const hostname = window.location.hostname;
  const envMatch = Object.keys(DEFAULT_CLIENT_CONFIG).find((env) =>
    hostname.includes(`${env}.controllershiphub`)
  );

  return envMatch || hostname.split('.')[0];
}
