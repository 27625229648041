import { ContentLayout, ContentLayoutProps } from '@cloudscape-design/components';

const PageLayout = ({
  defaultPadding = false,
  disableOverlap = true,
  headerVariant = 'default',
  maxContentWidth = Number.MAX_VALUE,
  ...props
}: ContentLayoutProps) => {
  return (
    <ContentLayout
      defaultPadding={defaultPadding}
      disableOverlap={disableOverlap}
      headerVariant={headerVariant}
      maxContentWidth={maxContentWidth}
      {...props}
    />
  );
};

export default PageLayout;
