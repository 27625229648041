import { createBrowserRouter, RouteObject } from 'react-router-dom';

import AppContainer from 'src/components/common/AppContainer/AppContainer';

import { ROUTES } from './routes';
import { ControlHomePage } from 'src/components/pages/ControlHomePage';
import { HistoricalExecutionsPage } from 'src/components/pages/HistoricalExecutionsPage';
import { ReportsPage } from 'src/components/pages/ReportsPage';
import { SummaryPage } from 'src/components/pages/SummaryPage';
import Landing from 'src/components/pages/LandingPage';

export const routeConfig = [
  {
    path: ROUTES.HOME,
    element: <AppContainer />,
    children: [
      {
        index: true,
        element: <Landing />,
      },
      {
        path: ROUTES.CONTROL.BASE,
        element: <ControlHomePage />,
      },
      {
        path: ROUTES.CONTROL.SUMMARY,
        element: <SummaryPage />,
      },
      {
        path: ROUTES.CONTROL.HISTORICAL,
        element: <HistoricalExecutionsPage />,
      },
      {
        path: ROUTES.REPORTS.DETAIL,
        element: <ReportsPage />,
      },
    ],
  },
];

export const router = createBrowserRouter(routeConfig as RouteObject[]);
