// ActiveFilters.tsx
import React, { useState } from 'react';
import {
  Button,
  SpaceBetween,
  ExpandableSection,
  Badge,
  Box,
  Modal,
  Input,
} from '@cloudscape-design/components';
import { FilterModel } from 'ag-grid-community';
import './ActiveFilters.scss';

/**
 * Interface for ActiveFilters component props
 * @interface ActiveFiltersProps
 * @property {FilterModel} filters - The current filter model from ag-grid
 * @property {function} onClearFilter - Callback function to clear a specific filter
 * @property {function} onClearAllFilters - Callback function to clear all filters
 * @property {function} onSaveFilterSet - Callback function to save the current filter set
 */
interface ActiveFiltersProps {
  filters: FilterModel;
  onClearFilter: (column: string) => void;
  onClearAllFilters: () => void;
  onSaveFilterSet: (filterSetName: string) => Promise<string>;
}

/**
 * ActiveFilters Component
 * Displays and manages active filters in an expandable section.
 * Provides functionality to remove individual filters, clear all filters,
 * and save the current filter set with a custom name.
 */
export const ActiveFilters: React.FC<ActiveFiltersProps> = ({
  filters,
  onClearFilter,
  onClearAllFilters,
  onSaveFilterSet,
}) => {
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const [filterSetName, setFilterSetName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  /**
   * Handles saving the current filter set
   * @async
   */
  const handleSaveFilterSet = async () => {
    setIsSaving(true);
    try {
      await onSaveFilterSet(filterSetName);
      handleCloseModal();
    } finally {
      setIsSaving(false);
    }
  };

  /**
   * Handles closing the save filter modal and resetting its state
   */
  const handleCloseModal = () => {
    setIsSaveModalVisible(false);
    setFilterSetName('');
  };

  const filterCount = Object.keys(filters).length;

  /**
   * Formats the filter display string based on the filter model values
   */
  const FilterSetDisplay: React.FC<{ column: string; displayName: string; values: string[] }> = ({
    column,
    displayName,
    values,
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    if (values.length <= 3) {
      return (
        <span>
          <span className="filter-text__column">{displayName}</span>
          <span className="filter-text__operator">: in [</span>
          <span className="filter-text__value">{values.join(', ')}</span>
          <span className="filter-text__operator">]</span>
        </span>
      );
    }

    return (
      <span>
        {isExpanded ? (
          <>
            <span className="filter-text__column">{displayName}</span>
            <span className="filter-text__operator">: in [</span>
            <span className="filter-text__value">{values.join(', ')}</span>
            <span className="filter-text__operator">] </span>
            <span
              onClick={() => setIsExpanded(false)}
              className="filter-more-link"
              role="button"
              tabIndex={0}
            >
              (Show less)
            </span>
          </>
        ) : (
          <>
            <span className="filter-text__column">{displayName}</span>
            <span className="filter-text__operator">: in [</span>
            <span className="filter-text__value">{values.slice(0, 3).join(', ')}</span>
            <span
              onClick={() => setIsExpanded(true)}
              className="filter-more-link"
              role="button"
              tabIndex={0}
            >
              {` ...+${values.length - 3} more`}
            </span>
            <span className="filter-text__operator">]</span>
          </>
        )}
      </span>
    );
  };

  /**
   * Formats the filter display string based on the filter model
   * @param {string} column - The column name
   * @param {any} model - The filter model
   * @returns {string} Formatted filter display string
   */
  const formatFilterDisplay = (
    column: string,
    displayName: string,
    model: any
  ): React.ReactNode => {
    try {
      if (!model) return '';

      if (model.filterType === 'multi' && model.filterModels) {
        const validModels = model.filterModels.filter(Boolean);
        return validModels
          .map((filterModel: any) => {
            if (!filterModel) return null;

            if (filterModel.filterType === 'text') {
              return (
                <span>
                  <span className="filter-text__column">{displayName}</span>
                  <span className="filter-text__operator">: {filterModel.type} "</span>
                  <span className="filter-text__value">{filterModel.filter}</span>
                  <span className="filter-text__operator">"</span>
                </span>
              );
            } else if (filterModel.filterType === 'set' && filterModel.values) {
              return (
                <FilterSetDisplay
                  column={column}
                  displayName={displayName}
                  values={filterModel.values}
                />
              );
            }
            return null;
          })
          .filter(Boolean);
      }

      if (model.type && model.filter) {
        return (
          <span>
            <span className="filter-text__column">{displayName}</span>
            <span className="filter-text__operator">: {model.type} "</span>
            <span className="filter-text__value">{model.filter}</span>
            <span className="filter-text__operator">"</span>
          </span>
        );
      }

      return '';
    } catch (error) {
      console.error('Error formatting filter display:', error);
      return '';
    }
  };

  return (
    <SpaceBetween direction="vertical" size="s">
      {filterCount > 0 && (
        <ExpandableSection
          variant="footer"
          headerText={
            <SpaceBetween direction="horizontal" size="xs">
              <span>Active Filters</span>
              <Badge color="blue">{filterCount}</Badge>
            </SpaceBetween>
          }
        >
          {Object.entries(filters || {})
            .map(([column, filter]) => {
              const displayValue = formatFilterDisplay(
                column,
                filter.displayName || column,
                filter
              );
              if (!displayValue) return null;

              return (
                <Box key={column} className="filter-container">
                  <div className="filter-display">
                    {displayValue}
                    <Button
                      variant="icon"
                      iconName="remove"
                      onClick={() => onClearFilter(column)}
                      aria-label={`Remove ${column} filter`}
                    />
                  </div>
                </Box>
              );
            })
            .filter(Boolean)}
          <Button
            data-testid="save-view-button"
            onClick={() => setIsSaveModalVisible(true)}
            iconName="status-positive"
          >
            Save as Current view
          </Button>
          <Box margin={{ left: 'xs' }} display="inline-block" />
          <Button
            data-testid="clear-all-filters-button"
            onClick={onClearAllFilters}
            iconName="remove"
          >
            Clear All
          </Button>
        </ExpandableSection>
      )}

      <Modal
        visible={isSaveModalVisible}
        onDismiss={handleCloseModal}
        header="Save Filter Set"
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSaveFilterSet}
                loading={isSaving}
                disabled={!filterSetName.trim() || isSaving || Object.keys(filters).length === 0}
              >
                Save
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <SpaceBetween direction="vertical" size="m">
          <Input
            value={filterSetName}
            onChange={({ detail }) => setFilterSetName(detail.value)}
            placeholder="Enter filter set name"
          />
        </SpaceBetween>
      </Modal>
    </SpaceBetween>
  );
};
