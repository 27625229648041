import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community';

/**
 * Custom cell editor for AG-Grid that preserves original value if input is left empty.
 * Implements ICellEditorComp interface with init, getGui, afterGuiAttached, getValue methods.
 * Creates an input element with AG-Grid classes and focuses it when attached.
 * @class CustomCellEditor - Use in column definition's cellEditor property.
 */
export class CustomCellEditor implements ICellEditorComp {
  private input!: HTMLInputElement;
  private originalValue!: string;

  init(params: ICellEditorParams) {
    this.input = document.createElement('input');
    this.input.classList.add('ag-input-field-input', 'ag-text-field-input');
    this.originalValue = params.value;
    this.input.value = ''; // Start with an empty input
  }

  getGui() {
    return this.input;
  }

  afterGuiAttached() {
    this.input.focus();
  }

  getValue() {
    return this.input.value === '' ? this.originalValue : this.input.value;
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }
}
